import React from 'react';
import Seo from './Seo';
import MainMenu from './MainMenu/MainMenu';
import Footer from './Footer/Footer';
import '../styles/style.scss';

const Layout = (props) => {
  const seoTitle =
    !!props.seoProps && props.seoProps.title !== undefined
      ? props.seoProps.title
      : '';
  const seoDescription =
    !!props.seoProps && props.seoProps.description !== undefined
      ? props.seoProps.description
      : '';

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />
      <MainMenu />
      <div id="layout-root">
        <div className="main-body">{props.children}</div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
