import React from 'react';
import { Navbar, Nav, Form } from 'react-bootstrap';
import filamentLogo from '../../images/filament-logo.svg';
import { Link } from 'gatsby';
import SignupForm from '../SignupForm/SignupForm';

class MainMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      expanded: false,
    };
  }

  toggleNavbar = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  collapseNavbar = () => {
    this.setState({
      expanded: false,
    });
  };

  componentDidMount() {
    document
      .getElementById('layout-root')
      .addEventListener('click', this.collapseNavbar, { passive: true });
    window.addEventListener('scroll', this.collapseNavbar, { passive: true });
  }

  componentWillUnmount() {
    document
      .getElementById('layout-root')
      .removeEventListener('click', this.collapseNavbar);
    window.addEventListener('scroll', this.collapseNavbar);
  }

  render() {
    return (
      <Navbar
        variant="light"
        expand="lg"
        fixed="top"
        id="main-menu"
        expanded={this.state.expanded}
        onToggle={this.toggleNavbar}
        className="pl-md-5 pr-md-5 pt-4 pb-4"
      >
        <Link to="/">
          <Navbar.Brand className="pt-0 mr-0">
            <img id="nav-logo" src={filamentLogo} alt="Filament Logo" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" onClick={this.collapseNavbar}>
            <Nav.Item>
              <Link to="/#featuresNav" className="nav-link">
                Features
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/#faqNav" className="nav-link">
                FAQ
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/about" className="nav-link">
                About Us
              </Link>
            </Nav.Item>
            <Form className="ml-0 ml-lg-2 mt-2 mt-lg-0 mb-3 mb-md-0">
              <SignupForm btnId="menuBtn" type="menu" btnColor="pink" />
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default MainMenu;
