import React from 'react';
import { Button } from 'react-bootstrap';
import './CtaButton.scss';

const CtaButton = (props) => {
  const type = props.type ? props.type : '';
  const color = props.color ? props.color : '';
  return (
    <Button className={`primary-cta ${type} ${color}`} id={props.id}>
      Join Waitlist
    </Button>
  );
};

export default CtaButton;
