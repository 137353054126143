import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import './Footer.scss';
import filamentIcon from '../../images/icons/filament-icon.svg';
import textLogo from '../../images/filament-text-logo.svg';

const Footer = () => {
  return (
    <Container fluid className="footer">
      <Container>
        <Row>
          <Col className="text-center mt-5">
            <img src={filamentIcon} alt="Filament Icon" width="50" id="icon" />
            <br />
            <img
              src={textLogo}
              alt="Filament Text Logo"
              id="logo"
              width="160"
            />
          </Col>
        </Row>
        <Row className={'content'}>
          <Col
            className="legal"
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 1 }}
          >
            <p className="legal__copy mb-0">
              Filament is a trading name of Anomalous Technologies Ltd
              <br />
              101 Rose Street South Lane, Edinburgh, EH2 3JG
              <br />
              Incorporated in sunny Scotland: SC 609436
              <br />
              VAT registration number: GB 313 5812 26
              <br />
              &copy; Anomalous Technologies Ltd 2019
              <br />
            </p>
          </Col>
          <Col
            className="footerMenu text-center text-md-right mb-5 mb-md-0"
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 2 }}
          >
            <a href="mailto:hello@filament.so" target="_blank" rel="noreferrer">
              hello@filament.so
            </a>
            <ul className={'footerMenu__links'}>
              <li>
                <Link to="/about/">About Us</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions/">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
