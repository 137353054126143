import React from 'react';
import { Link } from 'gatsby';
import * as typeformEmbed from '@typeform/embed';
import CtaButton from '../CtaButton/CtaButton';

class SignupForm extends React.PureComponent {
  componentDidMount() {
    document
      .getElementById(this.props.btnId)
      .addEventListener('click', function () {
        const popup = typeformEmbed.makePopup(
          'https://form.typeform.com/to/s3uG2S8P',
          {
            mode: 'popup',
          }
        );
        popup.open();
      });
  }

  elem = () => {
    if (this.props.linkType === 'link') {
      return (
        <Link id={this.props.btnId} to={`#${this.props.btnId}`}>
          {this.props.children}
        </Link>
      );
    } else {
      return (
        <CtaButton
          id={this.props.btnId}
          type={this.props.type}
          color={this.props.btnColor}
        />
      );
    }
  };

  render() {
    return this.elem();
  }
}

export default SignupForm;
